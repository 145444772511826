import React, { Fragment } from "react";
import { Box, Container } from "@bilar/ui";
import initFirebase from "@bilar/firebase/initFirebase";
import {
	GetStaticProps,
	GetStaticPropsContext,
	GetStaticPropsResult,
} from "next";
import { FrontPageHero } from "@bilar/layout";
import {
	BasePageProps,
	SectionHeading,
	useAppTranslation,
} from "@bilar/common";
import { getLatestVehicles, LatestVehicles } from "@bilar/features";
import { getBasePagePropsData } from "@bilar/pages-common";
import { ClassifiedVehicle } from "@bilar/models";
import { routes } from "@bilar/config";
import Head from "next/head";

const firebaseApp = initFirebase();

type HomePageProps = {
	latestVehicles: ClassifiedVehicle[];
} & BasePageProps;

const HomePage = (props: HomePageProps) => {
	const { t } = useAppTranslation();

	return (
		<Fragment>
			<Head>
				<title>{`Bílar.is - ${t("titleTag")}`}</title>
			</Head>
			{/*<MiniSearch />*/}
			{/*<RouteTransition>*/}
			<FrontPageHero />

			<Box pb={6}>
				<Container>
					<SectionHeading
						as="h3"
						title={t("latestCars")}
						linkTitle={t("seeMoreCars")}
						link={routes.vehicleClassifieds.index}
					/>
					<LatestVehicles data={props.latestVehicles} />
				</Container>
			</Box>
			{/*</RouteTransition>*/}
		</Fragment>
	);
};

export const getStaticProps: GetStaticProps<HomePageProps> = async (
	ctx: GetStaticPropsContext,
): Promise<GetStaticPropsResult<HomePageProps>> => {
	// await deleteDummyData();
	const data = await getLatestVehicles(9);

	// Get Locale and common translations
	const baseProps = await getBasePagePropsData(ctx);

	return {
		props: {
			latestVehicles: data,
			...baseProps,
		},
		// we will attempt to re-generate the page:
		// - when a request comes in
		// - at most once every second
		revalidate: 60,
	};
};

export default HomePage;
